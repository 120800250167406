import React from "react";
import Title from "../title/title";
import DSEWrapper from "../DSEWrapper/DSEWrapper";
import * as styles from "./CancerCareTeam.module.scss";
import clsx from "clsx";
import DseCtaIcon from '../DseCtaIcon/DseCtaIcon';

export default function CancerCareTeam() {
  return (
    <section className={clsx(styles.cancerCareTeam, "dse-bg-gradient-green-dark-light")}>
      <DSEWrapper fullWidth={true}>
        <Title className={styles.title} text="WHO IS ON MY CANCER CARE TEAM?" />
      </DSEWrapper>
      <DSEWrapper>
        <p className={clsx(styles.subtitle, "dse-light-text")}>
          Your care will be led by your oncologist, who is responsible for your
          treatment plan, but every member of your team plays an important role.
          Your team members may include:
        </p>
        <ul className={styles.list}>
          <li>Medical oncologist (primary provider)</li>
          <li>Nurses</li>
          <li>Social workers</li>
          <li>Radiation oncologists</li>
          <li>Nurse navigators</li>
          <li>Nutritionists</li>
        </ul>
        <p>Your NPC journey will start by meeting with your healthcare provider to ask any questions you may have.</p>
        <DseCtaIcon text="Browse frequently asked questions <br class='d-none d-lg-block'/>about nasopharyngeal cancer" href="/frequently-asked-questions/" classes={styles.ctaIcon}/>
      </DSEWrapper>
    </section>
  );
}
