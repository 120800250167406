import React from "react";
import DSEWrapper from "../DSEWrapper/DSEWrapper";
import IconTitleText from "../IconTitleText/IconTitleText";
import Title from "../title/title";
import icon1 from "../../../assets/images/pages/diagnosis/clipboard-icon.png";
import icon2 from "../../../assets/images/pages/diagnosis/stethoscope-icon.png";

const CauseNpc = () => {
  return (
    <section className="dse-diagnosed-npc">
      <DSEWrapper className="dse-diagnosed-npc__title" fullWidth={true}>
        <Title text="HOW IS NASOPHARYNGEAL CANCER DIAGNOSED?" isH1={true}/>
      </DSEWrapper>
      <DSEWrapper className="dse-diagnosed-npc__causes">
        <p className="dse-diagnosed-npc__subtitle dse-light-text">In order to diagnose nasopharyngeal cancer, your healthcare provider may consider the following:</p>
        <div className="dse-diagnosed-npc__causes-list">
          <IconTitleText icon={icon1} title="MEDICAL HISTORY" iconAlt="">
            <p>When meeting with a healthcare professional, they may ask about your health and
            medical history.</p>
            <p>They may also ask you about your family’s medical history to determine whether that could be a factor.</p>
          </IconTitleText>
          <IconTitleText icon={icon2} title="EXAM" iconAlt="">
            <p>Your healthcare provider will ask about any symptoms you are having.</p>
            <p>They will examine you to look for signs of nasopharyngeal cancer and may conduct additional tests to better understand your condition and its effects. These may include:</p>
            <ul>
              <li>Neurological exam</li>
              <li>Radiologic study (PET scan, CT scan, MRI, and/or ultrasound)</li>
              <li>Endoscopy or nasopharyngoscopy</li>
              <li>Biopsy/lab tests</li>
              <li>Hearing test</li>
            </ul>
          </IconTitleText>
        </div>
      </DSEWrapper>
    </section>
  )
}

export default CauseNpc;