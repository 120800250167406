import React from 'react';

const Ribbon = ({ className, children }) => {

  return (
    <section className={ `dse-ribbon ${className || ''}` }>
      <div className='dse-ribbon__text'>
        { children }
      </div>
    </section>
  )
}

export default Ribbon
