import React from "react";
import Title from "../title/title";
import DSEWrapper from "../DSEWrapper/DSEWrapper";
import * as styles from "./WhatTreatment.module.scss";
import clsx from "clsx";
import Ribbon from "../ribbon/ribbon";

export default function WhatTreatment() {
  return (
    <section className={clsx(styles.whatTreatment, "dse-grad-bg-green-to-dark")}>
      <DSEWrapper fullWidth={true}>
        <Title
          className={styles.title}
          text="WHAT TREATMENT OPTIONS ARE AVAILABLE?"
        />
      </DSEWrapper>
      <DSEWrapper>
        <p className={clsx(styles.subtitle, "dse-light-text")}>
          Your treatment plan for nasopharyngeal cancer may include a
          combination of various cancer treatments such as:
        </p>
        <ul className={styles.list}>
          <li>Chemotherapy</li>
          <li>Radiation therapy</li>
          <li>
            Intensity-modulated radiation therapy (radiation therapy that uses
            3D modeling to precisely deliver radiation to the tumor)
          </li>
        </ul>

        <Ribbon className={styles.ribbon}>
          <p>
            Treatments beyond traditional chemotherapy and <br/>
            radiation therapy, such as immunotherapy, are also <br/>
            available to treat people with nasopharyngeal cancer
          </p>
        </Ribbon>
      </DSEWrapper>
    </section>
  );
}
